import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sidebarShow: true,
  }

export const sideBarShowSlice = createSlice({
    name:'sidebarShow',
    initialState: initialState,
    reducers:{
        setVisibility:(state, action) => {
            state.sidebarShow = action.payload.sidebarShow;
        },
    }
})

export const {setVisibility} = sideBarShowSlice.actions
export default sideBarShowSlice.reducer