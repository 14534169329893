import { configureStore } from '@reduxjs/toolkit'
import brandsReducer from './features/brands/redux/brandsSlice'
import categoriesSlice from './features/categories/redux/categoriesSlice'
import setsSlice from './features/sets/redux/setsSlice'
import materialsSlice from './features/materials/redux/materialsSlice'
import itemDetailsSlice from './features/item_details_variant/redux/itemDetailsSlice'
import itemsSlice from './features/items/redux/itemsSlice'
import  variantStocksSlice  from './features/item_variant_stocks/redux/variantStocksSlice'
import authSlice from './features/auth/redux/authSlice'
import sideBarShowSlice from './sideBarShowSlice'
import variantsSlice from './features/variants/redux/variantsSlice'
import ordersSlice from './features/orders/redux/ordersSlice'
import stockReportSlice from './features/stock_report/redux/stockReportSlice'

export default configureStore({
  reducer: {
    auth: authSlice,
    brands: brandsReducer,
    categories: categoriesSlice,
    sets: setsSlice,
    materials: materialsSlice,
    items: itemsSlice,
    variants: variantsSlice,
    itemDetails: itemDetailsSlice,
    variantStocks: variantStocksSlice,
    sidebarShow: sideBarShowSlice,
    orders: ordersSlice,
    stockReport: stockReportSlice,
  },
})