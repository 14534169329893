import { createSlice } from "@reduxjs/toolkit";

export const itemDetailsSlice = createSlice({
  name: "itemDetails",
  initialState: {
    isLoading: true,
    data: null,
    error: null,
  },
  reducers: {
    setItemDetails: (state, action) => {
      //state.data = {[action.payload.data.id] : action.payload.data};
      state.data = action.payload.data;
    },
    addVariant: (state, action) => {
      state.data.variants.push(action.payload.data);
    },
    updateVariant: (state, action) => {
      state.data.variants[action.payload.index] = action.payload.data;
    },
    removeVariant: (state, action) => {
      state.data.variants.splice(action.payload.index, 1);
    },
    error(state, action) {
      state.error = action.payload.error;
    },
  },
});

export const {  setItemDetails, addVariant, updateVariant, removeVariant, error } = itemDetailsSlice.actions;
export default itemDetailsSlice.reducer;
