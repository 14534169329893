import {createSlice} from '@reduxjs/toolkit'

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user: user }
  : { isLoggedIn: false, user: null };

export const authSlice = createSlice({
    name:'auth',
    initialState: initialState,
    reducers:{
        login: (state, action) => {
            //action.payload
            //let userJson = JSON.stringify({"name":'Salman'})
            let userJson = JSON.stringify(action.payload.user);
            localStorage.setItem("user", userJson)
            state.user= userJson;
            state.isLoggedIn= true;
        },
        logout: (state)=>{
            localStorage.clear()
            state.isLoggedIn= false;
            state.user = null;
        }
    }
})

export const {login, logout} = authSlice.actions
export default authSlice.reducer